import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'agrupaItens'
  })
  export class AgrupaItensListaPipe implements PipeTransform {
    transform(value: [], agrupador: string): string | null {
      if (value === null || value.length === 0) {
        return '';
      }
  
        return value.join(agrupador);
    }
  
  }